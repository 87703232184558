<velorum-navbar
  class="navbar"
  *ngIf="ui.showNavbar"
  [ngClass]="{'transparent': ui.showTransparentNavbar}"
></velorum-navbar>

<velorum-social-links *ngIf="ui.showSocialLinks"></velorum-social-links>

<div
  class="router-container"
  [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''"
>
  <router-outlet #outlet="outlet"></router-outlet>
</div>


<div plyr class="music-plyr" style="visibility: hidden;" [plyrPlaysInline]="true" [plyrSources]="music"></div>
