<div class="modal-header">
  <div class="heading d-flex align-items-center">
    <i class="fa-duotone fa-dot-circle"></i>
    <h3>REGISTER</h3>
  </div>
  <i
    class="close fa-duotone fa-times pull-right"
    (click)="dialog.hide()"
    tooltip="Close"
    placement="left"
    [delay]="500"
  ></i>
</div>

<div class="modal-body">
  <div class="container-fluid content">
    <div class="row">
      <velorum-register (loggedIn)="onLoggedIn()"></velorum-register>
    </div>
  </div>
</div>
