import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Clipboard } from '@angular/cdk/clipboard';
import { DomSanitizer } from '@angular/platform-browser';
import { Database, ref, update } from '@angular/fire/database';
import {
  Auth} from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import packageJson from '../../../../../../package.json';

import { environment } from '../../../environments/environment';
import { WalletData } from '../../types/wallet-data.interface';
import { PhantomWalletService } from '../../modules/shared/services/phantom.service';
import { CoinbaseWalletService } from '../../modules/shared/services/coinbase.service';
import { UserService } from '../../modules/shared/services/user.service';
import { SolflareWalletService } from '../../modules/shared/services/solflare.service';
import { NotificationsService } from '../../services/notifications.service';


@Component({
  selector: 'velorum-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss']
})
export class WalletModalComponent implements OnInit, OnDestroy  {
  public balances: WalletData = {
    loaded: false
  };
  private onPhantomWalletConnectedSubscription: Subscription;
  private onSolflareWalletConnectedSubscription: Subscription;
  private onCoinbaseWalletConnectedSubscription: Subscription;

  constructor(
    public dialog: BsModalRef,
    public phantom: PhantomWalletService,
    public coinbase: CoinbaseWalletService,
    public solflare: SolflareWalletService,
    public userService: UserService,
    private database: Database,
    private clipboard: Clipboard,
    private domSanitizer: DomSanitizer,
    private notificationsService: NotificationsService,
    @Optional() private auth: Auth
  ) {
    // Listen for the wallet connected event to trigger the db save if the user is authenticated
    this.onPhantomWalletConnectedSubscription =
      this.phantom.onWalletConnected$.subscribe((balances: WalletData) => this.onWalletConnected(balances));

    this.onSolflareWalletConnectedSubscription =
      this.solflare.onWalletConnected$.subscribe((balances: WalletData) => this.onWalletConnected(balances));

    this.onCoinbaseWalletConnectedSubscription =
      this.coinbase.onWalletConnected$.subscribe((balances: WalletData) => this.onWalletConnected(balances));

  }

  private onWalletConnected(balances: WalletData) {
    if (balances.loaded) {
      // Show balances in the UI
      this.balances = balances;
      // If logged in, take note
      if (this.userService.user?.uid) {
        const player = ref(
          this.database,
          `wallets/${this.userService.user.uid}/${balances.name?.toLocaleLowerCase()}/${balances.address}`
        );
        update(player, {
          sol: balances.sol,
          singularity: balances.singularity,
          velorum: balances.velorum,
          usdc: balances.usdc,
          nfts: balances.nftTokens
        });
      }
    }
  }

  ngOnInit(): void {
    this.phantom.init();
    this.solflare.init();
  }

  ngOnDestroy(): void {
    this.onPhantomWalletConnectedSubscription?.unsubscribe?.();
    this.onSolflareWalletConnectedSubscription?.unsubscribe?.();
    this.onCoinbaseWalletConnectedSubscription?.unsubscribe?.();
  }

  public get phantomIcon() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.phantom.wallet.icon);
  }

  public get solflareIcon() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.solflare.wallet.icon);
  }

  public get coinbaseIcon() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.coinbase.wallet.icon);
  }

  public get version() {
    return packageJson.version;
  }

  public get production() {
    return environment.production;
  }

  public get showAddress() {
    return environment.wallet.showAddress;
  }

  public get showBalances() {
    return environment.wallet.showBalances.enable;
  }

  public get showUSDC() {
    return environment.wallet.showBalances.usdc;
  }

  public get showUSDT() {
    return environment.wallet.showBalances.usdt;
  }

  public get showSingularity() {
    return environment.wallet.showBalances.singularity;
  }

  public get showVelorum() {
    return environment.wallet.showBalances.velorum;
  }

  public get connected() {
    return this.phantom.connected || this.solflare.connected || this.coinbase.connected;
  }

  public get refreshing() {
    return this.phantom.updating || this.solflare.updating || this.coinbase.updating;
  }

  public get walletAddress() {
    if (this.phantom.connected) {
      return this.phantom.walletAddress;
    } else if (this.solflare.connected) {
      return this.solflare.walletAddress;
    } else if (this.coinbase.connected) {
      return this.coinbase.walletAddress;
    }else {
      return null;
    }
  }

  async onPhantom(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    if (this.phantom.connected) {
      this.phantom.disconnect();
    } else {
      this.phantom.connect();
    }
  }


  async onSolflare(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    if (this.solflare.connected) {
      this.solflare.disconnect();
    } else {
      this.solflare.connect();
    }
  }

  copyAddressToClipboard() {
    if (this.balances.loaded) {
      this.clipboard.copy(this.balances.address as string);

      this.notificationsService.walletAddressCopiedToClipboard(this.balances.address as string);
    }
  }

}
