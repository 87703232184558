import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [],
  imports: [CommonModule, TooltipModule.forRoot(), ModalModule.forRoot(), AccordionModule.forRoot()],
  exports: [TooltipModule, ModalModule, AccordionModule],
})
export class BootstrapModule {}
