import { Injectable } from '@angular/core';
import { UnityContext } from '../modules/unity/models/unity-context.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnityService {

  public grnContext: UnityContext;
  public mecoContext: UnityContext;

  constructor() {

    this.grnContext = new UnityContext('grn', {
      loaderUrl: `${environment.games.grn.path}/Build/grn.loader.js`,
      dataUrl: `${environment.games.grn.path}/Build/grn.data`,
      frameworkUrl: `${environment.games.grn.path}/Build/grn.framework.js`,
      codeUrl: `${environment.games.grn.path}/Build/grn.wasm`,
      streamingAssetsUrl: `${environment.games.grn.path}/StreamingAssets`,
      companyName: "Covr",
      productName: "Singularity Gold Rush Nexus - Early Access",
      productVersion: "1510",
      matchWebGLToCanvasSize: true,
      devicePixelRatio: 1
    });

    this.mecoContext = new UnityContext('meco', {
      loaderUrl: `${environment.games.meco.path}/Build/meco.loader.js`,
      dataUrl: `${environment.games.meco.path}/Build/meco.data`,
      frameworkUrl: `${environment.games.meco.path}/Build/meco.framework.js`,
      codeUrl: `${environment.games.meco.path}/Build/meco.wasm`,
      streamingAssetsUrl: `${environment.games.meco.path}/StreamingAssets`,
      companyName: "Covr",
      productName: "Singularity MECO",
      productVersion: "1510",
    });

    this.grnContext.on('progress', (progress: number) => {
      // TODO: Move this to the app component and add a loading screen
      // console.log('Loading unity player ', Math.round(progress * 100))
    });
  }


  async destroyGrn() {
    return this.grnContext.quitUnityInstance();
  }

  async destroyMeco() {
    return this.mecoContext.quitUnityInstance();
  }
}


