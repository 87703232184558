import { Injectable } from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';

@Injectable()
export class MusicPlayerService {
  private _plyr!: PlyrComponent;


  public get plyr() {
    return this._plyr;
  }

  public set plyr(value: PlyrComponent) {
    this._plyr = value;
  }


}
