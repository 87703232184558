import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MarkdownModule } from 'ngx-markdown';
import { LightboxModule } from 'ngx-lightbox';
import { PlyrModule } from 'ngx-plyr';

// Custom Modules
import { SharedLibModule } from '@velorum/shared';
import { BootstrapModule } from './modules/bootstrap/bootstrap.module';
import { StorageModule } from './modules/storage/storage.module';
import { SharedModule } from './modules/shared/shared.module';
import { UnityModule } from './modules/unity/unity.module';
// Environment
import { environment } from '../environments/environment';
// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
// Routing
import { routes, routingOptions } from './app.routes';
// Views
import { AppView } from './views/app/app.view';
// Guards
import { DeactivateMecoGuard } from './guards/deactivate-meco.guard';
// Components
import { NavbarComponent } from './components/navbar/navbar.component';
import { DetailsComponent } from './components/details/details.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { RegisterModalComponent } from './components/register-modal/register-modal.component';
import { AvatarModalComponent } from './components/avatar-modal/avatar-modal.component';
import { WalletModalComponent } from './components/wallet-modal/wallet-modal.component';
import { WalletStatusBadgeComponent } from './components/wallet-status-badge/wallet-status-badge.component';
import { ProfileModalComponent } from './components/profile-modal/profile-modal.component';


@NgModule({
  declarations: [
    AppView,
    NavbarComponent,
    DetailsComponent,
    SocialLinksComponent,
    RegisterModalComponent,
    AvatarModalComponent,
    WalletModalComponent,
    WalletStatusBadgeComponent,
    ProfileModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BootstrapModule,
    ToastrModule.forRoot(),
    ClipboardModule,
    LightboxModule,
    PlyrModule,
    SharedLibModule,
    SharedModule.forRoot(),
    UnityModule.forRoot(),
    StorageModule.forRoot({
      prefix: 'VELORUM',
    }),
    MarkdownModule.forRoot(),

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),

    RouterModule.forRoot(routes, routingOptions),
  ],
  providers: [DeactivateMecoGuard],
  bootstrap: [AppView],
})
export class AppModule {}
