import { Injectable } from '@angular/core';
import { WalletConnectionError, WalletReadyState } from '@solana/wallet-adapter-base';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import {
  AccountInfo,
  Connection,
  LAMPORTS_PER_SOL,
  ParsedAccountData,
  PublicKey,
} from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  nftTokenIds,
  WalletData,
} from '../../../types/wallet-data.interface';
import { TimerService } from './timer.service';
import { NotificationsService } from '../../../services/notifications.service';
import { PurchaseCurrency } from '../../../services/transactions.service';

import { Database, ref, get, query } from '@angular/fire/database';
import { equalTo, orderByChild } from 'firebase/database';

const SOLFLARE_BALANCES_TIMER = 'REFRESH_SOLFLARE_WALLET_BALANCES_TIMER';
const SOLFLARE_BALANCES_TIMER_REFRESH_TIME = 60 * 60 * 1000; // refresh balances every 60 minutes

@Injectable()
export class SolflareWalletService {
  public wallet!: SolflareWalletAdapter;
  public connection!: Connection;

  public updating = false;

  public balances: WalletData = { loaded: false, name: 'Solflare' };

  public onWalletConnected$: BehaviorSubject<WalletData> = new BehaviorSubject({
    loaded: false,
  } as WalletData);

  public onWalletDisconnected$: Subject<boolean> = new Subject();

  constructor(
    private notificationsService: NotificationsService,
    private timerService: TimerService,
    public database: Database
  ) {
    this.timerService.newTimer(SOLFLARE_BALANCES_TIMER, SOLFLARE_BALANCES_TIMER_REFRESH_TIME);
    this.timerService.find(SOLFLARE_BALANCES_TIMER).start();

    this.connection = new Connection(
      environment.solana.cluster,
      'finalized'
    );

    this.wallet = new SolflareWalletAdapter();

    // Refresh balances every 60 minutes if the wallet is connected
    this.timerService.find(SOLFLARE_BALANCES_TIMER).onTick.subscribe(() => {
      if (this.wallet.connected) {
        this.onSolflareWalletConnected(this.connection, this.wallet);
      }
    });
  }

  public get connected() {
    return this.wallet.connected;
  }

  public get connecting() {
    return this.wallet.connecting;
  }

  public get walletAddress() {
    return this.wallet.publicKey?.toString();
  }

  // TODO: This doesnt belong in the service, move to the UI itself
  public get buttonText() {
    return this.connected
      ? 'DISCONNECT'
      : this.connecting
      ? 'CONNECTING'
      : 'CONNECT';
  }

  // Don't do this automatically on the constructor or the users will get the annoying popup if their password has expired
  // early on.. instead wait until they've cleared the intro and the navbar is visible, then check
  init() {
    this.wallet.on('disconnect', () => this.onSolflareWalletDisconnected());
    this.wallet.on('connect', () => {
      this.onSolflareWalletConnected(this.connection, this.wallet)
    });
  }

  public async connect() {
    await this.wallet.connect().catch((error: WalletConnectionError) => {
      if (error.name === 'WalletNotReadyError') {
        // Solflare Wallet Not Installed
        this.notificationsService.cantFindWalletWarning();
      } else if (error.error.code === 4001) {
        // User rejected the request, show notification
        this.notificationsService.walletConnectionRequestRejected();
      }
    });
  }

  public disconnect() {
    this.wallet.disconnect();
  }

  async onSolflareWalletConnected(
    connection: Connection,
    wallet: SolflareWalletAdapter
  ) {
    this.updating = true;
    await this.updateBalances(connection, wallet);
  }

  private async updateBalances(
    connection: Connection,
    wallet: SolflareWalletAdapter
  ) {
    // Get the balances of the tokens that matter
    const solBalance =
      (await connection.getBalance(wallet.publicKey as PublicKey)) /
      LAMPORTS_PER_SOL;

    // Include every account created by the SPL token program
    const accounts = await connection.getParsedProgramAccounts(
      TOKEN_PROGRAM_ID,
      {
        filters: [
          {
            dataSize: 165, // number of bytes
          },
          {
            memcmp: {
              offset: 32, // number of bytes
              bytes: (wallet.publicKey as PublicKey).toBase58(), // base58 encoded string
            },
          },
        ],
      }
    );

    // Use the parsedAccounts data instead of raw
    const parsedAccounts = accounts
      .map((a) => a.account as AccountInfo<ParsedAccountData>)
      .map((a) => a.data.parsed.info);

    const tokenAccounts = parsedAccounts.filter(
      (a) => a.tokenAmount.decimals > 0
    );

    const nftAccounts = parsedAccounts.filter(
      (a) => a.tokenAmount.decimals === 0 && a.tokenAmount.uiAmount > 0
    );

    // for (let i = 0; i < nftAccounts.length; i++) {
    //   console.log(`Looking for mint: ${nftAccounts[i].mint}`);
    //   const nftResource = ref(this.database, `mints/resources/supergate/${nftAccounts[i].mint}`);
    //   const resource = await get(query(nftResource, orderByChild('state'), equalTo('sold')));
    //   if (resource.exists()) {
    //     console.log('Resource found: ', resource.val());
    //   }
    // }

    this.balances = {
      loaded: true,
      name: 'Solflare',
      address: wallet.publicKey?.toString(),
      sol: solBalance,
      singularity: this.getSingularityBalance(tokenAccounts),
      velorum: this.getVelorumBalance(tokenAccounts),
      usdc: this.getUSDCBalance(tokenAccounts),
      usdt: this.getUSDTBalance(tokenAccounts),
      nftTokens: this.getNFTTokenBalances(nftAccounts),
    };

    // Not updating anymore
    this.updating = false;

    // Notify the subscribers this happened
    this.onWalletConnected$.next(this.balances);
  }

  public hasSol(amount: number): boolean {
    return !this.balances.loaded
      ? false
      : (this.balances.sol as number) >= amount;
  }

  public hasUSDC(amount: number): boolean {
    return !this.balances.loaded
      ? false
      : (this.balances.usdc as number) >= amount;
  }

  public hasUSDT(amount: number): boolean {
    return !this.balances.loaded
      ? false
      : (this.balances.usdt as number) >= amount;
  }

  private getMintAccount(accounts: any[], mint: string): any {
    return accounts.find((a) => a.mint === mint);
  }

  async onSolflareWalletDisconnected() {
    this.onWalletDisconnected$.next(true);
  }

  private getSingularityBalance(accounts: any[]) {
    return (
      this.getMintAccount(
        accounts,
        environment.solana.addresses.singularity.address
      )?.tokenAmount.uiAmount || 0
    );
  }

  private getVelorumBalance(accounts: any[]) {
    return (
      this.getMintAccount(
        accounts,
        environment.solana.addresses.velorum.address
      )?.tokenAmount.uiAmount || 0
    );
  }

  private getUSDCBalance(accounts: any[]) {
    return (
      this.getMintAccount(accounts, environment.solana.addresses.usdc.address)
        ?.tokenAmount.uiAmount || 0
    );
  }

  private getUSDTBalance(accounts: any[]) {
    return (
      this.getMintAccount(accounts, environment.solana.addresses.usdt.address)
        ?.tokenAmount.uiAmount || 0
    );
  }

  private getNFTTokenBalances(
    accounts: any[]
  ): { id: nftTokenIds; amount: number }[] {
    return [
      {
        id: 'razor',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.razor
        ),
      },
      {
        id: 'qf50',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf50
        ),
      },
      {
        id: 'qf100',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf100
        ),
      },
      {
        id: 'qf250',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf250
        ),
      },
      {
        id: 'qf500',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf500
        ),
      },
      {
        id: 'qf1000',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf1000
        ),
      },
      {
        id: 'qf2000',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf2000
        ),
      },
      {
        id: 'qf10000',
        amount: this.getNFTTokenBalance(
          accounts,
          environment.solana.mints.qf10000
        ),
      },
    ];
  }

  private getNFTTokenBalance(accounts: any[], tokenMint: string) {
    return this.getMintAccount(accounts, tokenMint)?.tokenAmount.uiAmount || 0;
  }

  public hasEnoughBalance(currency: PurchaseCurrency, amount: number): boolean {
    switch (currency) {
      case 'SOL':
        return this.hasSol(amount);
      case 'USDC':
        return this.hasUSDC(amount);
      case 'USDT':
        return this.hasUSDT(amount);
    }
  }
}
