<!-- Social media icons -->
<div class="social">
  <a
    href="https://twitter.com/SingularityMeta"
    target="_blank"
    tooltip="Follow us on Twitter"
    placement="top"
    [delay]="500"
    container="body"
  >
    <i class="fab fa-twitter fa-lg"></i>
  </a>
  <a
    href="https://discord.gg/S5agh3UfpZ"
    target="_blank"
    tooltip="Join our Discord Server"
    placement="top"
    [delay]="500"
    container="body"
  >
    <i class="fab fa-discord fa-lg"></i>
  </a>
  <a
    href="https://whitepaper.velorum.games"
    target="_blank"
    tooltip="Read the White Paper"
    placement="top"
    [delay]="500"
    container="body"
  >
    <i class="fa-duotone fa-book-open fa-lg"></i>
  </a>
  <a
    href="https://quasar.blog"
    target="_blank"
    tooltip="Read the Blog"
    placement="top"
    [delay]="500"
    container="body"
  >
    <i class="fa-duotone fa-rss fa-lg"></i>
  </a>
</div>
