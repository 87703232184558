import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event, ReplayEvent } from '../events/base.event';


@Injectable()
export class EventManagerService {
  private _events: Array<Event<any> | ReplayEvent<any>> = [];

  public get events() {
    return this._events;
  }

  public on<T extends Event<any> | ReplayEvent<any>>(event: { new(): T }, next: (value: T['type']) => void): Subscription {
    // Find the event
    const ev: T = this._events.find(e => e.constructor.name === event.name) as T;

    // If it has not been created, instantiate it
    if (!ev) {
      this._events.push(new event());
    }

    // Now subscribe!
    return (this._events.find(e => e.constructor.name === event.name) as T)?.subscribe(next);
  }

  public off<T extends Event<any> | ReplayEvent<any>>(event: { new(): T }, subscription?: Subscription) {
    // Find the event
    const ev: T = this._events.find(e => e.constructor.name === event.name) as T;

    ev?.dispose?.(subscription);
  }

  public emit<T extends Event<any> | ReplayEvent<any>>(event: { new(): T }, params?: T['type']) {
    // Find the event
    const ev: T = this._events.find(e => e.constructor.name === event.name) as T;

    // If it has not been created, instantiate it
    if (!ev) {
      this._events.push(new event());
    }


    const nev: T = this._events.find(e => e.constructor.name === event.name) as T;

    nev?.emit(params);
  }

  public dispose(): void {
    // First unsub
    this._events.forEach(event => event?.dispose?.()); // Make it an optional invoke just in case Angular already got rid of it

    // Then delete
    this._events = [];
  }

}
