import { ExtraOptions, Route } from '@angular/router';
// Guards
import { DeactivateMecoGuard } from './guards/deactivate-meco.guard';

export const routes: Route[] = [
  {
    path: '',
    data: { name: 'root' },
    loadChildren: () =>
      import('./modules/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'velorum',
    data: { name: 'galaxy' },
    loadChildren: () =>
      import('./modules/galaxy/galaxy.module').then((m) => m.GalaxyModule),
  },
  {
    path: 'vcom',
    data: { name: 'vcom' },
    loadChildren: () =>
      import('./modules/vcom/vcom.module').then((m) => m.VcomModule),
  },
  {
    path: 'meco',
    data: { name: 'meco' },
    canDeactivate: [DeactivateMecoGuard],
    loadChildren: () =>
      import('./modules/meco/meco.module').then((m) => m.MecoModule),
  }
];

export const routingOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};
