import { StellarClass } from '../types/stellarclass.enum';
import { ColorTemperature } from './color-temperature.class';

export class TemperatureToColor {

  public static Colors: Array<ColorTemperature> = [
    new ColorTemperature('1000', '255', '51', '0', '#ff3300'),
    new ColorTemperature('1100', '255', '69', '0', '#ff4500'),
    new ColorTemperature('1200', '255', '82', '0', '#ff5200'),
    new ColorTemperature('1300', '255', '93', '0', '#ff5d00'),
    new ColorTemperature('1400', '255', '102', '0', '#ff6600'),
    new ColorTemperature('1500', '255', '111', '0', '#ff6f00'),
    new ColorTemperature('1600', '255', '118', '0', '#ff7600'),
    new ColorTemperature('1700', '255', '124', '0', '#ff7c00'),
    new ColorTemperature('1800', '255', '130', '0', '#ff8200'),
    new ColorTemperature('1900', '255', '135', '0', '#ff8700'),
    new ColorTemperature('2000', '255', '141', '11', '#ff8d0b'),
    new ColorTemperature('2100', '255', '146', '29', '#ff921d'),
    new ColorTemperature('2200', '255', '152', '41', '#ff9829'),
    new ColorTemperature('2300', '255', '157', '51', '#ff9d33'),
    new ColorTemperature('2400', '255', '162', '60', '#ffa23c'),
    new ColorTemperature('2500', '255', '166', '69', '#ffa645'),
    new ColorTemperature('2600', '255', '170', '77', '#ffaa4d'),
    new ColorTemperature('2700', '255', '174', '84', '#ffae54'),
    new ColorTemperature('2800', '255', '178', '91', '#ffb25b'),
    new ColorTemperature('2900', '255', '182', '98', '#ffb662'),
    new ColorTemperature('3000', '255', '185', '105', '#ffb969'),
    new ColorTemperature('3100', '255', '189', '111', '#ffbd6f'),
    new ColorTemperature('3200', '255', '192', '118', '#ffc076'),
    new ColorTemperature('3300', '255', '195', '124', '#ffc37c'),
    new ColorTemperature('3400', '255', '198', '130', '#ffc682'),
    new ColorTemperature('3500', '255', '201', '135', '#ffc987'),
    new ColorTemperature('3600', '255', '203', '141', '#ffcb8d'),
    new ColorTemperature('3700', '255', '206', '146', '#ffce92'),
    new ColorTemperature('3800', '255', '208', '151', '#ffd097'),
    new ColorTemperature('3900', '255', '211', '156', '#ffd39c'),
    new ColorTemperature('4000', '255', '213', '161', '#ffd5a1'),
    new ColorTemperature('4100', '255', '215', '166', '#ffd7a6'),
    new ColorTemperature('4200', '255', '217', '171', '#ffd9ab'),
    new ColorTemperature('4300', '255', '219', '175', '#ffdbaf'),
    new ColorTemperature('4400', '255', '221', '180', '#ffddb4'),
    new ColorTemperature('4500', '255', '223', '184', '#ffdfb8'),
    new ColorTemperature('4600', '255', '225', '188', '#ffe1bc'),
    new ColorTemperature('4700', '255', '226', '192', '#ffe2c0'),
    new ColorTemperature('4800', '255', '228', '196', '#ffe4c4'),
    new ColorTemperature('4900', '255', '229', '200', '#ffe5c8'),
    new ColorTemperature('5000', '255', '231', '204', '#ffe7cc'),
    new ColorTemperature('5100', '255', '232', '208', '#ffe8d0'),
    new ColorTemperature('5200', '255', '234', '211', '#ffead3'),
    new ColorTemperature('5300', '255', '235', '215', '#ffebd7'),
    new ColorTemperature('5400', '255', '237', '218', '#ffedda'),
    new ColorTemperature('5500', '255', '238', '222', '#ffeede'),
    new ColorTemperature('5600', '255', '239', '225', '#ffefe1'),
    new ColorTemperature('5700', '255', '240', '228', '#fff0e4'),
    new ColorTemperature('5800', '255', '241', '231', '#fff1e7'),
    new ColorTemperature('5900', '255', '243', '234', '#fff3ea'),
    new ColorTemperature('6000', '255', '244', '237', '#fff4ed'),
    new ColorTemperature('6100', '255', '245', '240', '#fff5f0'),
    new ColorTemperature('6200', '255', '246', '243', '#fff6f3'),
    new ColorTemperature('6300', '255', '247', '245', '#fff7f5'),
    new ColorTemperature('6400', '255', '248', '248', '#fff8f8'),
    new ColorTemperature('6500', '255', '249', '251', '#fff9fb'),
    new ColorTemperature('6600', '255', '249', '253', '#fff9fd'),
    new ColorTemperature('6700', '254', '250', '255', '#fefaff'),
    new ColorTemperature('6800', '252', '248', '255', '#fcf8ff'),
    new ColorTemperature('6900', '250', '247', '255', '#faf7ff'),
    new ColorTemperature('7000', '247', '245', '255', '#f7f5ff'),
    new ColorTemperature('7100', '245', '244', '255', '#f5f4ff'),
    new ColorTemperature('7200', '243', '243', '255', '#f3f3ff'),
    new ColorTemperature('7300', '241', '241', '255', '#f1f1ff'),
    new ColorTemperature('7400', '239', '240', '255', '#eff0ff'),
    new ColorTemperature('7500', '238', '239', '255', '#eeefff'),
    new ColorTemperature('7600', '236', '238', '255', '#eceeff'),
    new ColorTemperature('7700', '234', '237', '255', '#eaedff'),
    new ColorTemperature('7800', '233', '236', '255', '#e9ecff'),
    new ColorTemperature('7900', '231', '234', '255', '#e7eaff'),
    new ColorTemperature('8000', '229', '233', '255', '#e5e9ff'),
    new ColorTemperature('8100', '228', '233', '255', '#e4e9ff'),
    new ColorTemperature('8200', '227', '232', '255', '#e3e8ff'),
    new ColorTemperature('8300', '225', '231', '255', '#e1e7ff'),
    new ColorTemperature('8400', '224', '230', '255', '#e0e6ff'),
    new ColorTemperature('8500', '223', '229', '255', '#dfe5ff'),
    new ColorTemperature('8600', '221', '228', '255', '#dde4ff'),
    new ColorTemperature('8700', '220', '227', '255', '#dce3ff'),
    new ColorTemperature('8800', '219', '226', '255', '#dbe2ff'),
    new ColorTemperature('8900', '218', '226', '255', '#dae2ff'),
    new ColorTemperature('9000', '217', '225', '255', '#d9e1ff'),
    new ColorTemperature('9100', '216', '224', '255', '#d8e0ff'),
    new ColorTemperature('9200', '215', '223', '255', '#d7dfff'),
    new ColorTemperature('9300', '214', '223', '255', '#d6dfff'),
    new ColorTemperature('9400', '213', '222', '255', '#d5deff'),
    new ColorTemperature('9500', '212', '221', '255', '#d4ddff'),
    new ColorTemperature('9600', '211', '221', '255', '#d3ddff'),
    new ColorTemperature('9700', '210', '220', '255', '#d2dcff'),
    new ColorTemperature('9800', '209', '220', '255', '#d1dcff'),
    new ColorTemperature('9900', '208', '219', '255', '#d0dbff'),
    new ColorTemperature('10000', '207', '218', '255', '#cfdaff'),
    new ColorTemperature('10100', '207', '218', '255', '#cfdaff'),
    new ColorTemperature('10200', '206', '217', '255', '#ced9ff'),
    new ColorTemperature('10300', '205', '217', '255', '#cdd9ff'),
    new ColorTemperature('10400', '204', '216', '255', '#ccd8ff'),
    new ColorTemperature('10500', '204', '216', '255', '#ccd8ff'),
    new ColorTemperature('10600', '203', '215', '255', '#cbd7ff'),
    new ColorTemperature('10700', '202', '215', '255', '#cad7ff'),
    new ColorTemperature('10800', '202', '214', '255', '#cad6ff'),
    new ColorTemperature('10900', '201', '214', '255', '#c9d6ff'),
    new ColorTemperature('11000', '200', '213', '255', '#c8d5ff'),
    new ColorTemperature('11100', '200', '213', '255', '#c8d5ff'),
    new ColorTemperature('11200', '199', '212', '255', '#c7d4ff'),
    new ColorTemperature('11300', '198', '212', '255', '#c6d4ff'),
    new ColorTemperature('11400', '198', '212', '255', '#c6d4ff'),
    new ColorTemperature('11500', '197', '211', '255', '#c5d3ff'),
    new ColorTemperature('11600', '197', '211', '255', '#c5d3ff'),
    new ColorTemperature('11700', '196', '210', '255', '#c4d2ff'),
    new ColorTemperature('11800', '196', '210', '255', '#c4d2ff'),
    new ColorTemperature('11900', '195', '210', '255', '#c3d2ff'),
    new ColorTemperature('12000', '195', '209', '255', '#c3d1ff'),
    new ColorTemperature('12100', '194', '209', '255', '#c2d1ff'),
    new ColorTemperature('12200', '194', '208', '255', '#c2d0ff'),
    new ColorTemperature('12300', '193', '208', '255', '#c1d0ff'),
    new ColorTemperature('12400', '193', '208', '255', '#c1d0ff'),
    new ColorTemperature('12500', '192', '207', '255', '#c0cfff'),
    new ColorTemperature('12600', '192', '207', '255', '#c0cfff'),
    new ColorTemperature('12700', '191', '207', '255', '#bfcfff'),
    new ColorTemperature('12800', '191', '206', '255', '#bfceff'),
    new ColorTemperature('12900', '190', '206', '255', '#beceff'),
    new ColorTemperature('13000', '190', '206', '255', '#beceff'),
    new ColorTemperature('13100', '190', '206', '255', '#beceff'),
    new ColorTemperature('13200', '189', '205', '255', '#bdcdff'),
    new ColorTemperature('13300', '189', '205', '255', '#bdcdff'),
    new ColorTemperature('13400', '188', '205', '255', '#bccdff'),
    new ColorTemperature('13500', '188', '204', '255', '#bcccff'),
    new ColorTemperature('13600', '188', '204', '255', '#bcccff'),
    new ColorTemperature('13700', '187', '204', '255', '#bbccff'),
    new ColorTemperature('13800', '187', '204', '255', '#bbccff'),
    new ColorTemperature('13900', '187', '203', '255', '#bbcbff'),
    new ColorTemperature('14000', '186', '203', '255', '#bacbff'),
    new ColorTemperature('14100', '186', '203', '255', '#bacbff'),
    new ColorTemperature('14200', '186', '203', '255', '#bacbff'),
    new ColorTemperature('14300', '185', '202', '255', '#b9caff'),
    new ColorTemperature('14400', '185', '202', '255', '#b9caff'),
    new ColorTemperature('14500', '185', '202', '255', '#b9caff'),
    new ColorTemperature('14600', '184', '202', '255', '#b8caff'),
    new ColorTemperature('14700', '184', '201', '255', '#b8c9ff'),
    new ColorTemperature('14800', '184', '201', '255', '#b8c9ff'),
    new ColorTemperature('14900', '184', '201', '255', '#b8c9ff'),
    new ColorTemperature('15000', '183', '201', '255', '#b7c9ff'),
    new ColorTemperature('15100', '183', '201', '255', '#b7c9ff'),
    new ColorTemperature('15200', '183', '200', '255', '#b7c8ff'),
    new ColorTemperature('15300', '182', '200', '255', '#b6c8ff'),
    new ColorTemperature('15400', '182', '200', '255', '#b6c8ff'),
    new ColorTemperature('15500', '182', '200', '255', '#b6c8ff'),
    new ColorTemperature('15600', '182', '200', '255', '#b6c8ff'),
    new ColorTemperature('15700', '181', '199', '255', '#b5c7ff'),
    new ColorTemperature('15800', '181', '199', '255', '#b5c7ff'),
    new ColorTemperature('15900', '181', '199', '255', '#b5c7ff'),
    new ColorTemperature('16000', '181', '199', '255', '#b5c7ff'),
    new ColorTemperature('16100', '180', '199', '255', '#b4c7ff'),
    new ColorTemperature('16200', '180', '198', '255', '#b4c6ff'),
    new ColorTemperature('16300', '180', '198', '255', '#b4c6ff'),
    new ColorTemperature('16400', '180', '198', '255', '#b4c6ff'),
    new ColorTemperature('16500', '179', '198', '255', '#b3c6ff'),
    new ColorTemperature('16600', '179', '198', '255', '#b3c6ff'),
    new ColorTemperature('16700', '179', '198', '255', '#b3c6ff'),
    new ColorTemperature('16800', '179', '197', '255', '#b3c5ff'),
    new ColorTemperature('16900', '179', '197', '255', '#b3c5ff'),
    new ColorTemperature('17000', '178', '197', '255', '#b2c5ff'),
    new ColorTemperature('17100', '178', '197', '255', '#b2c5ff'),
    new ColorTemperature('17200', '178', '197', '255', '#b2c5ff'),
    new ColorTemperature('17300', '178', '197', '255', '#b2c5ff'),
    new ColorTemperature('17400', '178', '196', '255', '#b2c4ff'),
    new ColorTemperature('17500', '177', '196', '255', '#b1c4ff'),
    new ColorTemperature('17600', '177', '196', '255', '#b1c4ff'),
    new ColorTemperature('17700', '177', '196', '255', '#b1c4ff'),
    new ColorTemperature('17800', '177', '196', '255', '#b1c4ff'),
    new ColorTemperature('17900', '177', '196', '255', '#b1c4ff'),
    new ColorTemperature('18000', '176', '196', '255', '#b0c4ff'),
    new ColorTemperature('18100', '176', '195', '255', '#b0c3ff'),
    new ColorTemperature('18200', '176', '195', '255', '#b0c3ff'),
    new ColorTemperature('18300', '176', '195', '255', '#b0c3ff'),
    new ColorTemperature('18400', '176', '195', '255', '#b0c3ff'),
    new ColorTemperature('18500', '176', '195', '255', '#b0c3ff'),
    new ColorTemperature('18600', '175', '195', '255', '#afc3ff'),
    new ColorTemperature('18700', '175', '195', '255', '#afc3ff'),
    new ColorTemperature('18800', '175', '194', '255', '#afc2ff'),
    new ColorTemperature('18900', '175', '194', '255', '#afc2ff'),
    new ColorTemperature('19000', '175', '194', '255', '#afc2ff'),
    new ColorTemperature('19100', '175', '194', '255', '#afc2ff'),
    new ColorTemperature('19200', '174', '194', '255', '#aec2ff'),
    new ColorTemperature('19300', '174', '194', '255', '#aec2ff'),
    new ColorTemperature('19400', '174', '194', '255', '#aec2ff'),
    new ColorTemperature('19500', '174', '194', '255', '#aec2ff'),
    new ColorTemperature('19600', '174', '194', '255', '#aec2ff'),
    new ColorTemperature('19700', '174', '193', '255', '#aec1ff'),
    new ColorTemperature('19800', '174', '193', '255', '#aec1ff'),
    new ColorTemperature('19900', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20000', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20100', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20200', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20300', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20400', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20500', '173', '193', '255', '#adc1ff'),
    new ColorTemperature('20600', '173', '192', '255', '#adc0ff'),
    new ColorTemperature('20700', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('20800', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('20900', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('21000', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('21100', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('21200', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('21300', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('21400', '172', '192', '255', '#acc0ff'),
    new ColorTemperature('21500', '171', '192', '255', '#abc0ff'),
    new ColorTemperature('21600', '171', '192', '255', '#abc0ff'),
    new ColorTemperature('21700', '171', '191', '255', '#abbfff'),
    new ColorTemperature('21800', '171', '191', '255', '#abbfff'),
    new ColorTemperature('21900', '171', '191', '255', '#abbfff'),
    new ColorTemperature('22000', '171', '191', '255', '#abbfff'),
    new ColorTemperature('22100', '171', '191', '255', '#abbfff'),
    new ColorTemperature('22200', '171', '191', '255', '#abbfff'),
    new ColorTemperature('22300', '171', '191', '255', '#abbfff'),
    new ColorTemperature('22400', '170', '191', '255', '#aabfff'),
    new ColorTemperature('22500', '170', '191', '255', '#aabfff'),
    new ColorTemperature('22600', '170', '191', '255', '#aabfff'),
    new ColorTemperature('22700', '170', '191', '255', '#aabfff'),
    new ColorTemperature('22800', '170', '190', '255', '#aabeff'),
    new ColorTemperature('22900', '170', '190', '255', '#aabeff'),
    new ColorTemperature('23000', '170', '190', '255', '#aabeff'),
    new ColorTemperature('23100', '170', '190', '255', '#aabeff'),
    new ColorTemperature('23200', '170', '190', '255', '#aabeff'),
    new ColorTemperature('23300', '170', '190', '255', '#aabeff'),
    new ColorTemperature('23400', '169', '190', '255', '#a9beff'),
    new ColorTemperature('23500', '169', '190', '255', '#a9beff'),
    new ColorTemperature('23600', '169', '190', '255', '#a9beff'),
    new ColorTemperature('23700', '169', '190', '255', '#a9beff'),
    new ColorTemperature('23800', '169', '190', '255', '#a9beff'),
    new ColorTemperature('23900', '169', '190', '255', '#a9beff'),
    new ColorTemperature('24000', '169', '190', '255', '#a9beff'),
    new ColorTemperature('24100', '169', '190', '255', '#a9beff'),
    new ColorTemperature('24200', '169', '189', '255', '#a9bdff'),
    new ColorTemperature('24300', '169', '189', '255', '#a9bdff'),
    new ColorTemperature('24400', '169', '189', '255', '#a9bdff'),
    new ColorTemperature('24500', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('24600', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('24700', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('24800', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('24900', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25000', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25100', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25200', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25300', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25400', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25500', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25600', '168', '189', '255', '#a8bdff'),
    new ColorTemperature('25700', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('25800', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('25900', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26000', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26100', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26200', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26300', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26400', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26500', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26600', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26700', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26800', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('26900', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('27000', '167', '188', '255', '#a7bcff'),
    new ColorTemperature('27100', '166', '188', '255', '#a6bcff'),
    new ColorTemperature('27200', '166', '188', '255', '#a6bcff'),
    new ColorTemperature('27300', '166', '188', '255', '#a6bcff'),
    new ColorTemperature('27400', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('27500', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('27600', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('27700', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('27800', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('27900', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28000', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28100', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28200', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28300', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28400', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28500', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28600', '166', '187', '255', '#a6bbff'),
    new ColorTemperature('28700', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('28800', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('28900', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('29000', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('29100', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('29200', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('29300', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('29400', '165', '187', '255', '#a5bbff'),
    new ColorTemperature('29500', '165', '186', '255', '#a5baff'),
    new ColorTemperature('29600', '165', '186', '255', '#a5baff'),
    new ColorTemperature('29700', '165', '186', '255', '#a5baff'),
    new ColorTemperature('29800', '165', '186', '255', '#a5baff'),
    new ColorTemperature('29900', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30000', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30100', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30200', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30300', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30400', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30500', '165', '186', '255', '#a5baff'),
    new ColorTemperature('30600', '164', '186', '255', '#a4baff'),
    new ColorTemperature('30700', '164', '186', '255', '#a4baff'),
    new ColorTemperature('30800', '164', '186', '255', '#a4baff'),
    new ColorTemperature('30900', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31000', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31100', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31200', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31300', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31400', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31500', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31600', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31700', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31800', '164', '186', '255', '#a4baff'),
    new ColorTemperature('31900', '164', '186', '255', '#a4baff'),
    new ColorTemperature('32000', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32100', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32200', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32300', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32400', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32500', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32600', '164', '185', '255', '#a4b9ff'),
    new ColorTemperature('32700', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('32800', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('32900', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33000', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33100', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33200', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33300', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33400', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33500', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33600', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33700', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33800', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('33900', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34000', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34100', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34200', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34300', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34400', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34500', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34600', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34700', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34800', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('34900', '163', '185', '255', '#a3b9ff'),
    new ColorTemperature('35000', '163', '184', '255', '#a3b8ff'),
    new ColorTemperature('35100', '163', '184', '255', '#a3b8ff'),
    new ColorTemperature('35200', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35300', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35400', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35500', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35600', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35700', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35800', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('35900', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36000', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36100', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36200', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36300', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36400', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36500', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36600', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36700', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36800', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('36900', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37000', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37100', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37200', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37300', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37400', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37500', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37600', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37700', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37800', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('37900', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('38000', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('38100', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('38200', '162', '184', '255', '#a2b8ff'),
    new ColorTemperature('38300', '161', '184', '255', '#a1b8ff'),
    new ColorTemperature('38400', '161', '184', '255', '#a1b8ff'),
    new ColorTemperature('38500', '161', '184', '255', '#a1b8ff'),
    new ColorTemperature('38600', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('38700', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('38800', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('38900', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39000', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39100', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39200', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39300', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39400', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39500', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39600', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39700', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39800', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('39900', '161', '183', '255', '#a1b7ff'),
    new ColorTemperature('40000', '161', '183', '255', '#a1b7ff'),
  ];

  public static StellarClassColors: Array<ColorTemperature> = [
    new ColorTemperature('0', '1', '1', '1', '#f3d569'), // Main Sequence
    new ColorTemperature('0', '1', '1', '1', '#e3866e'), // RedDwarf
    new ColorTemperature('0', '1', '1', '1', '#e3866e'), // RedGiant
    new ColorTemperature('0', '1', '1', '1', '#75c8fb'), // BlueGiant
    new ColorTemperature('0', '1', '1', '1', '#d4edf7'), // SuperGiant
    new ColorTemperature('0', '1', '1', '1', '#ffffff'), // WhiteDwarf
    new ColorTemperature('0', '1', '1', '1', '#e9f2f8'), // Pulsar
    new ColorTemperature('0', '1', '1', '1', '#bddcbd'), // NeutronStar
  ];

  public static GetColorFromExactTemperature(kelvin: number): ColorTemperature | undefined {
    return this.Colors.find(c => c.Temperature === kelvin);
  }

  public static GetColorFromStellarClass(type: StellarClass): ColorTemperature | undefined {
    return this.StellarClassColors[type];
  }

  // WARNING: THIS IS EXTREMELY EXPENSIVE ON 100K RECORDS
  public static GetColorFromTemperature(kelvin: number): ColorTemperature {
    return this.Colors.sort((x, y) => Math.abs(x.Temperature - kelvin) > Math.abs(y.Temperature - kelvin) ? 1 : -1)[0];
  }

}
