import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private toastrService: ToastrService) { }

  public walletClosedError() {
    return this.toastrService.error(
      'Transaction Request Rejected',
      'Phantom Wallet',
      {
        progressBar: true,
        timeOut: 4000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }

  public registrationRequiredError() {
    return this.toastrService.error(
      'Please register to be able to purchase.',
      'Marketplace',
      {
        progressBar: true,
        timeOut: 4000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }

  public cantFindWalletWarning() {
    return this.toastrService.warning(
      "Can't find your Wallet, please make sure it is installed in your browser.",
      'Wallet',
      {
        progressBar: true,
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }

  public walletConnectionRequestRejected() {
    return this.toastrService.error(
      'Wallet Connection Request Rejected',
      'Wallet',
      {
        progressBar: true,
        timeOut: 4000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    )
  }

  public waitingToConfirmTransaction() {
    return this.toastrService.success(
      "Transaction created, please wait for network confirmation...",
      'Wallet',
      {
        progressBar: true,
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }

  public cantConfirmTransaction() {
    return this.toastrService.error(
      'Cannot confirm the Transaction, please wait ...',
      'Wallet',
      {
        progressBar: true,
        timeOut: 4000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    )
  }

  public transactionConfirmed() {
    return this.toastrService.success(
      "Transaction confirmed, your items will be delivered in a few seconds ...",
      'Wallet',
      {
        progressBar: true,
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }

  public MECOTransactionConfirmed() {
    return this.toastrService.success(
      "Confirmed, you will get access to this Solar System in a few seconds ...",
      'Wallet',
      {
        progressBar: true,
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }

  public walletAddressCopiedToClipboard(address: string) {
    return this.toastrService.success(
      "Connected Wallet Address copied to the Clipboard",
      'Wallet',
      {
        progressBar: true,
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        toastClass: 'singularity-toast'
      }
    );
  }
}
