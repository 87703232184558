import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnityLoaderService } from './services/unity-loader.service';

import { UnityComponent } from './components/unity/unity.component';

@NgModule({
  declarations: [UnityComponent],
  imports: [CommonModule],
  providers: [],
  exports: [UnityComponent]
})
export class UnityModule {
  static forRoot(): ModuleWithProviders<UnityModule> {
    return {
        ngModule: UnityModule,
        providers: [UnityLoaderService]
    };
  }
}
