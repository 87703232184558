
import { Galaxy, Sector, SolarSystem, Planet } from "../models";
import { ObjectData } from "../types/galaxy-data.interface";
import { BaseEvent, BaseReplayEvent } from "./base.event";

// Basic type events
export class OnNumberEvent extends BaseEvent<number> {};
export class OnStringEvent extends BaseEvent<string> {};
export class OnBooleanEvent extends BaseEvent<boolean> {};

// Data Events
export class OnGalaxyModelReadyEvent extends BaseReplayEvent<Galaxy> {};
export class OnDataLoadedEvent extends BaseReplayEvent<{sectors: Sector[], systems: SolarSystem[], planets: Planet[], pois: any[]}> {};
export class OnPlayerClaimedSectorsLoadedEvent extends BaseReplayEvent {};
export class OnPlayerClaimedSolarSystemsLoadedEvent extends BaseReplayEvent {};

// Game State Events
export class OnScenesReadyEvent extends BaseReplayEvent {};
export class OnLoadingScreenFadedOutEvent extends BaseReplayEvent {};
export class OnGameStartedEvent extends BaseEvent {};
export class OnGameStartingEvent extends BaseEvent {};
export class OnGameViewChangedEvent extends BaseEvent {};


// Galaxy View Events
export class OnGalaxyCalloutClickEvent extends BaseEvent<ObjectData> {};
export class OnGalaxyCalloutToggleEvent extends BaseEvent {};
export class OnGalaxySectorClickEvent extends BaseEvent<{ id: number, x: number, y: number}> {};

export class OnSectorSelectedEvent extends BaseEvent<Sector> {};
export class OnSectorDeselectedEvent extends BaseEvent {};


// Sector View Events
// TODO: Do OnBeforeSeleced OnAfterSelected instead of this mess
export class OnClickSolarSystemEvent extends BaseEvent<SolarSystem> {};
export class OnSelectSolarSystemEvent extends BaseEvent<SolarSystem> {};
export class OnSolarSystemSelectedEvent extends BaseReplayEvent<SolarSystem> {};
export class OnSolarSystemDeselectedEvent extends BaseReplayEvent {};

// Intro Events
export class OnIntroAlreadyCompletedEvent extends BaseEvent {};
export class OnIntroArgosCalloutClickEvent extends BaseEvent {};
export class OnIntroBackToGalaxyViewEvent extends BaseEvent {};
export class OnIntroCompleteEvent extends BaseEvent {};
export class OnIntroCompleteSaveEvent extends BaseEvent {};
export class OnIntroPlaySequenceEvent extends BaseEvent {};
export class OnIntroSequenceStepEndEvent extends BaseEvent {};
export class OnIntroSequenceStepStartEvent extends BaseEvent {};
export class OnIntroShowAllCalloutsEvent extends BaseEvent {};
export class OnIntroShowArgosCalloutEvent extends BaseEvent {};
export class OnIntroShowSaulCalloutEvent extends BaseEvent {};
export class OnIntroShowSocialIconsEvent extends BaseEvent {};
export class OnIntroShowSystemReticleEvent extends BaseEvent {};
export class OnIntroStartEvent extends BaseEvent {};
export class OnIntroSystemReticleClickEvent extends BaseEvent {};

// UI Events
export class OnShowHeadlineEvent extends BaseEvent<{ text: string, duration: number }> {};
export class OnShowSeparatorsEvent extends BaseEvent {};
export class OnShowGalaxyStatsEvent extends BaseEvent {};
export class OnShowSectorStatsEvent extends BaseEvent {};
