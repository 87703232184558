import { Component, Optional } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../modules/shared/services/user.service';
import { WindowsService } from '../../modules/shared/services/windows.service';

@Component({
  selector: 'velorum-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  public readonly user: Observable<User | null> = EMPTY;

  constructor(
    private readonly windowsService: WindowsService,
    public readonly userService: UserService,
    @Optional() private auth: Auth,
  ) {

    // Show the odin navbar button only if admin
    if (auth) {
      this.user = authState(this.auth);
    }

  }

  public get showGRN() {
    return environment.grn.visible;
  }


  public get showMECO() {
    return environment.meco.visible;
  }

  public get photoURL() {
    return this.userService.user?.photoURL
      ? this.userService.user.photoURL
      : '';
  }

  public get profileTitle() {
    return this.userService.user?.displayName
      ? this.userService.user.displayName
      : 'Your Profile';
  }

  public openProfile() {
    this.windowsService.openProfileModal();
  }
}
