// Firebase points to silicon project
import { environmentCommon } from './environment.common';

export const environment = {
  ...environmentCommon,
  name: 'Silicon',
  production: false,
  useFirebaseEmulators: false,
  log: {
    level: 1,
    date: false,
  },
  firebase: {
    apiKey: 'AIzaSyCnn6Hf_P3xWsucqGUDgyuFdbpScQu0QJ8',
    authDomain: 'singularity-silicon.firebaseapp.com',
    databaseURL: 'https://singularity-silicon-default-rtdb.firebaseio.com',
    projectId: 'singularity-silicon',
    storageBucket: 'singularity-silicon.appspot.com',
    messagingSenderId: '57827709095',
    appId: '1:57827709095:web:3064c1dbc422843ede4b82',
    measurementId: 'G-B19BY52CGD',
  },
  games: {
    grn: {
      path: 'https://silicon.cloud.cdn.velorum.games/game/grn',
      debug: false,
      build: 'v1.2.5000'
    },
    meco: {
      path: 'https://silicon.cloud.cdn.velorum.games/game/meco'
    }
  },
  wallet: {
    showAddress: true,
    showBalances: {
      enable: true,
      usdc: true,
      usdt: true,
      singularity: false,
      velorum: false,
    },
  },
  analytics: {
    gtag: 'https://www.googletagmanager.com/gtag/js?id=G-42E4LSVWSD',
  },
  cdn: {
    host: 'cdn.velorum.games',
    path: 'silicon',
    map: {
      stars: 'velorum/stars-compressed.json',
      colors: 'velorum/stars-colors.json',
      names: 'velorum/star-names.json',
    },
    vcom: {
      lore: 'vcom/lore',
      speaker: {
        images: 'vcom/speaker',
        audio: 'vcom/speaker/audio'
      }
    },
    game: {
      sectors: 'galaxy/sectors-meco.json',
      systems: 'galaxy/solar-systems-meco.json',
      celestials: 'galaxy/celestials.json',
      structures: 'galaxy/structures.json',
      colors: 'galaxy/colors.json'
    }
  },
  odin: {
    host: 'https://us-central1-singularity-silicon.cloudfunctions.net/odin'
  },
  solana: {
    cluster: 'https://solana-devnet.g.alchemy.com/v2/Dva8IqHGWk3ZZ58pTakId19DVXcPdJx6',
    wallets: {
      minting: '5LhXEph5Jii7TFKZ3sgd7F1pkxNG2kEXzfuDECauEsdg',
      nfts: 'BZSj1BpHwjMsGBnDgf1fLNHjyZY4mmRQNFGLEHQV8tvm',
      receivables: 'DE5QYSpDsuHK8kLBd2Jyf6Jmw4vr72q5e5qvL2wVtCsk',
      treasury: '6bC1s1RJni8ZXrzQoUtp7yfERqP9K6GfpWmTgUhyGbpp',
      rewards: '6kBRR76RD4Mw3GjvFjLomMG8pKYbCm7w56oeY3RwV6cq',
      private: '8j25KzzNG7xFUHffDEjrQv7KYoZt2rAxTA76cytCMYYv',
      public: 'CZre4Ua9yADGE2CU71PsWPshxn1oShS9LsAizwTf1Tt7',
      team: 'H3P4FagumFdZTbtPCPhedQQmWVVHxsGgvFjSKFB4jbPS'
    },
    addresses: {
      usdt: {
        address: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
        account: '',
      },
      usdc: {
        address: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
        account: 'HqZpkevxaNcVtsTH5RKKKhgky3HPhiagVCCeCBTk5co4',
      },
      singularity: {
        address: '3sJWs1uNphtHMVrqqP6foFxNTi1XLa6BZiyftq41y6kQ',
        account: 'EAYgN2ee8hwMZCK13NGrYfvwdtsucRUniM9MQHjEbCiE',
      },
      velorum: {
        address: '3485gPZHaNvhXC15HqfSUojefN2xPfg5mJCrnY6BNU6T',
        account: '4dHeHN4WbUoSXv9E2Hy7dTKaHv3r9aXMusTmwgb9NzRV',
      },
    },
    mints: {
      supergate: '8qQGhTWctVHeEMSUCQUsnCBhRzGPW6jtGv8Ce3GS6aWt',
      razor: '5Vh6faaneTLpnZzR8NvDf7tSStMKAkQNLqeqCd2CYBm',
      qf50: 'GCPcUZhRcBSujNiygMK3ExudefCHPevBHiCHhhZVqssn',
      qf100: '7iPjGoDFEHVAzj5WPthbFdusWwRVpcEfTQpHfJuGCM8R',
      qf250: 'D85XPmgijM9uUFDeohjqkZ8YMXPAnejFRR8HDdRnTJsG',
      qf500: 'ARcbk7AhuZcGdT7GsNnTZh97Dbj2RrzPAN4PhYsLK8Fo',
      qf1000: '8Aoxxmi8fkEk7iff7WeE8TgCZnYE7qomKC5zBzJQsyy8',
      qf2000: '3k8Y3fDYF6Ck6uB3M1asXpBfsGnz3RxPtG5XmSgwD4pA',
      qf10000: '95faNfWkxsS1FicrNfvNAm9KEW7QeCG3dHDTT1WMesfe'
    }
  },
  market: {
    enabled: false,
  },
  meco: {
    visible: false,
  },
  grn: {
    visible: true,
  },
  profile: {
    ranks: false,
    avatar: false,
  },
  newmap: {
    visible: true,
  },
};
