<div class="modal-header d-flex align-items-center">
  <i class="fa-duotone fa-dot-circle"></i>
  <h3 class="ms-3 mb-0 me-auto label">CONNECT YOUR WALLET</h3>
  <i
    class="close fa-duotone fa-times pull-right"
    (click)="dialog.hide()"
    tooltip="Close"
    placement="left"
    [delay]="500"
  ></i>
</div>

<div class="modal-body">
  <div class="container-fluid content wallet-container">
    <div class="row mb-2"  *ngIf="!solflare.connected && !coinbase.connected">
      <!-- Phantom -->
      <div class="col col-12 px-0">
        <div
          class="wallet d-flex flex-row justify-content-between align-items-center phantom"
        >
          <div class="d-flex justify-content-around align-items-center">
            <img class="logo" [src]="phantomIcon" />
            <h5>PHANTOM</h5>
          </div>

          <button
            class="btn btn-outline-light position-relative"
            (click)="onPhantom($event)"
          >
            {{ phantom.buttonText }}
            <velorum-wallet-status-badge *ngIf="phantom.connected"></velorum-wallet-status-badge>
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!phantom.connected && !coinbase.connected">
      <!-- SolFlare -->
      <div class="col col-12 px-0">
        <div
          class="wallet d-flex flex-row justify-content-between align-items-center solflare"
        >
          <div class="d-flex justify-content-around align-items-center">
            <img class="logo" [src]="solflareIcon" />
            <h5>SOLFLARE</h5>
          </div>

          <button
            class="btn btn-outline-light position-relative"
            (click)="onSolflare($event)"
          >
            {{ solflare.buttonText }}
            <velorum-wallet-status-badge *ngIf="solflare.connected"></velorum-wallet-status-badge>
          </button>
        </div>
      </div>
    </div>
    <!-- Address -->
    <h3 class="label" *ngIf="showAddress && connected">WALLET ADDRESS</h3>
    <div class="row mb-2" *ngIf="showAddress && connected">
      <div class="col col-12 px-0">
        <div
          class="address d-flex flex-row justify-content-between align-items-center"
        >
          <h5
            class="hash"
            tooltip="Wallet Address"
            placement="top"
            [delay]="500"
          >
            {{ walletAddress }}
          </h5>
          <i
            class="fa-duotone fa-copy"
            (click)="copyAddressToClipboard()"
            tooltip="Copy to the Clipboard"
            placement="top"
          ></i>
        </div>
      </div>
    </div>
    <!-- Balances -->
    <h3 class="label" *ngIf="showBalances && connected">WALLET BALANCES</h3>
    <div class="row" *ngIf="showBalances && connected">
      <div class="col col-12 px-0">
        <div
          class="balance d-flex flex-column justify-content-between align-items-center"
        >
        <div
          class="coin d-flex flex-row justify-content-between align-items-center"
        >
          <div class="logo sol mr-2"></div>
          <h5 class="symbol mr-2">SOL</h5>
          <i></i>
          <h5 class="amount" *ngIf="!refreshing">
            {{ balances.sol?.toLocaleString() }}
          </h5>
        </div>
        <div *ngIf="showUSDC"
            class="coin d-flex flex-row justify-content-between align-items-center"
          >
            <div class="logo usdc mr-2"></div>
            <h5 class="symbol mr-2">USDC</h5>
            <i></i>
            <h5 class="amount" *ngIf="!refreshing">
              {{ balances.usdc?.toLocaleString() }}
            </h5>
          </div>
          <div *ngIf="showUSDT"
          class="coin d-flex flex-row justify-content-between align-items-center"
        >
          <div class="logo usdt mr-2"></div>
          <h5 class="symbol mr-2">USDT</h5>
          <i></i>
          <h5 class="amount" *ngIf="!refreshing">
            {{ balances.usdt?.toLocaleString() }}
          </h5>
        </div>
        <div *ngIf="showSingularity"
            class="coin d-flex flex-row justify-content-between align-items-center"
          >
            <div class="logo singularity mr-2"></div>
            <h5 class="symbol mr-2">SINGULARITY</h5>
            <i></i>
            <h5 class="amount" *ngIf="!refreshing">
              {{ balances.singularity?.toLocaleString() }}
            </h5>
          </div>
          <div *ngIf="showVelorum"
            class="coin d-flex flex-row justify-content-between align-items-center"
          >
            <div class="logo velorum mr-2"></div>
            <h5 class="symbol mr-2">VELORUM</h5>
            <i></i>
            <h5 class="amount" *ngIf="!refreshing">
              {{ balances.velorum?.toLocaleString() }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
