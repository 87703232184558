import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  Data,
} from '@angular/router';
import { PlyrComponent } from 'ngx-plyr';
import { filter, map, switchMap } from 'rxjs/operators';
import { setTheme } from 'ngx-bootstrap/utils';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { fadeAnimation } from '../../animations/fade.animation';
import { MusicPlayerService } from '../../modules/shared/services/musicplayer.service';
import { UserService } from '../../modules/shared/services/user.service';
import { UIService } from '../../services/ui.service';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'velorum-root',
  templateUrl: './app.view.html',
  styleUrls: ['./app.view.scss'],
  animations: [fadeAnimation],
})
export class AppView implements AfterViewInit, OnDestroy {
  @ViewChild(PlyrComponent) plyr!: PlyrComponent;

  public routeData$: Observable<Data>;
  private routeSubscription!: Subscription;
  private googleAnalyticsSubscription!: Subscription;


  public music: Plyr.Source[] = [
    {
      src: `${this.audioCdnPath}/002-music.mp3`,
      provider: 'html5',
    },
    {
      src: `${this.audioCdnPath}/001-music.mp3`,
      provider: 'html5',
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public readonly ui: UIService,
    private readonly musicPlayer: MusicPlayerService,
    private readonly userService: UserService,
  ) {
    // Set the ngxbotstrap theme
    setTheme('bs5');

    // Get the route loaded and dont trigger any animation shit unless we are in the root
    this.routeData$ = this.router.events.pipe(
      filter((routeEvent) => routeEvent instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((activatedRoute) => activatedRoute.firstChild),
      switchMap((firstChild) => (firstChild as ActivatedRoute).data),
      map((data: Data) => data)
    );

    // Move the navbar away if we get to the game
    this.routeSubscription = this.routeData$.subscribe((data) => {
      // GRN!!
      this.ui.showTransparentNavbar = data['name'] === 'galaxy';
      this.ui.showNavbar = data['name'] !== 'meco-game';
      this.ui.showSocialLinks = data['name'] !== 'meco-game' && data['name'] !== 'galaxy';
    });

    // Google analytics only in prod
    if (environment.production) {
      this.googleAnalyticsSubscription = this.router.events.subscribe(
        (event) => {
          if (event instanceof NavigationEnd) {
            gtag('config', environment.firebase.measurementId, {
              page_path: event.urlAfterRedirects,
            });
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe?.();
    this.googleAnalyticsSubscription?.unsubscribe?.();
  }

  ngAfterViewInit(): void {
    // Keep a reference to the music player in the music player service
    this.musicPlayer.plyr = this.plyr;
  }

  get audioCdnPath() {
    return `//${environment.cdn.host}/${environment.cdn.path}/meco/audio/music`;
  }
}
