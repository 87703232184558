import { Component, Input, OnInit } from '@angular/core';
import { UnityContext } from '../../models/unity-context.model';
import { UnityLoaderService } from '../../services/unity-loader.service';

@Component({
  selector: 'velorum-unity',
  templateUrl: './unity.component.html',
  styleUrls: ['./unity.component.scss']
})
export class UnityComponent implements OnInit {
  private unityLoaderService: UnityLoaderService = new UnityLoaderService();
  private htmlCanvasElementReference!: HTMLCanvasElement;

  @Input() context!: UnityContext;
  @Input() id!: string;


  async ngOnInit() {
    await this.unityLoaderService.addFromUrl(this.context.unityConfig.loaderUrl);

    this.htmlCanvasElementReference = document.querySelector(`#${this.id}`) as HTMLCanvasElement;
    const unityInstanceParams = {
      ...this.context.unityConfig,
      devicePixelRatio: 2,
      matchWebGLToCanvasSize: true
    };

    const _unityInstance = await window.createUnityInstance(
      this.htmlCanvasElementReference,
      unityInstanceParams,
      this.onProgress.bind(this)
    );

    this.context.setUnityInstance(_unityInstance);
  }

  onProgress(progress: number) {
    this.context.dispatchEventListener("progress", progress);
    if (progress === 1) {
      this.context.dispatchEventListener("loaded");
      if (typeof this.htmlCanvasElementReference !== "undefined")
        this.context.dispatchEventListener(
          "canvas",
          this.htmlCanvasElementReference
        );
    }
  }
}
