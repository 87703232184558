<div class="navbar-item logo" routerLink="/"></div>

<div
  *ngIf="showGRN"
  class="navbar-item"
  tooltip="Galaxy Map"
  placement="right"
  [delay]="250"
  container="body"
  routerLink="/velorum"
  routerLinkActive="selected"
  [routerLinkActiveOptions]="{ exact: true }"
>
  <i class="icon fa-duotone fa-solar-system fa-lg"></i>
</div>

<div
  class="navbar-item"
  tooltip="Velorum Universal Communications Network VCOM"
  placement="right"
  [delay]="250"
  container="body"
  routerLink="/vcom"
  routerLinkActive="selected"
>
  <i class="icon fa-duotone fa-circle-nodes fa-lg"></i>
</div>

<div
  *ngIf="showMECO"
  class="navbar-item"
  tooltip="MECO"
  placement="right"
  [delay]="250"
  container="body"
  routerLink="/meco"
  routerLinkActive="selected"
  [routerLinkActiveOptions]="{ exact: true }"
>
  <i class="icon fa-duotone fa-rocket-launch fa-lg"></i>
</div>

<div class="flex-fill"></div>

<div
  class="navbar-item account"
  tooltip="Your Profile"
  placement="right"
  [delay]="250"
  container="body"
  (click)="openProfile()"
>
  <i class="icon fa-duotone fa-user-cog fa-lg" *ngIf="!photoURL"></i>
  <div
    class="avatar"
    *ngIf="photoURL"
    [ngStyle]="{ 'background-image': 'url(' + photoURL + ')' }"
  ></div>
</div>
