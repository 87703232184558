import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Database } from '@angular/fire/database';
import { Auth, authState, signOut } from '@angular/fire/auth';
import formatDistance from 'date-fns/formatDistance';
import { traceUntilFirst } from '@angular/fire/performance';
import { Subscription } from 'rxjs';
import packageJson from '../../../../../../../../package.json';

import { environment } from '../../../../../environments/environment';
import { PhantomWalletService } from '../../services/phantom.service';
import { UserService } from '../../services/user.service';
import { WindowsService } from '../../services/windows.service';
import { SolflareWalletService } from '../../services/solflare.service';

@Component({
  selector: 'velorum-profile',
  templateUrl: './profile.view.html',
  styleUrls: ['./profile.view.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private readonly userDisposable: Subscription | undefined;

  public loggedIn = false;

  constructor(
    public phantom: PhantomWalletService,
    public solflare: SolflareWalletService,
    public userService: UserService,
    private database: Database,
    private clipboard: Clipboard,
    private windowsService: WindowsService,
    @Optional() private auth: Auth
  ) {
    if (auth) {
      this.userDisposable = authState(this.auth)
        .pipe(traceUntilFirst('auth'))
        .subscribe((user) => {
          // Show the login/logout buttons depending on the state
          this.loggedIn = !!user;
        });
    }
  }

  ngOnInit(): void {
    this.phantom.init();
  }

  ngOnDestroy(): void {
    this.userDisposable?.unsubscribe?.();
  }

  public get version() {
    return packageJson.version;
  }

  public get production() {
    return environment.production;
  }

  public get displayName() {
    return this.userService.user?.displayName
      ? this.userService.user.displayName
      : '';
  }

  public get photoURL() {
    return this.userService.user?.photoURL
      ? this.userService.user.photoURL
      : '';
  }

  public get accountAge() {
    return this.userService.user?.metadata.creationTime
      ? formatDistance(
          Date.parse(this.userService.user.metadata.creationTime),
          new Date()
        ) + ' ago'
      : '';
  }

  async logout() {
    return await signOut(this.auth);
  }
}
