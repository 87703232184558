<div class="modal-header">
  <div class="heading d-flex align-items-center">
    <i class="fa-duotone fa-dot-circle"></i>
    <h3>{{ selectedObject.name }}</h3>
  </div>
  <i
    class="close fa-duotone fa-times pull-right"
    (click)="dialog.hide()"
    tooltip="Close"
    placement="left"
    [delay]="500"
  ></i>
</div>

<div class="modal-body">
  <div class="container-fluid content">
    <div class="row mb-3 d-flex justify-content-center position-relative">
      <div
        class="image mb-4 ps-0 pe-0"
        (click)="open()"
        [ngStyle]="{ 'background-image': 'url(' +  imagesCdnPath + '/' + (selectedObject.src) + ')', 'background-position-y': '' + selectedObject.backgroundy + '' }"
      ></div>
      <ng-template #loading>Loading...</ng-template>
      <div class="image-overlay">
        <h1 class="text-uppercase">{{ selectedObject.name  }}</h1>
      </div>
      <div class="image-expand-overlay">
        <i class="fa-duotone fa-lg fa-expand"
        (click)="open()"
          tooltip="Click to expand"
          placement="left"
          [delay]="100"
          container="body"></i>
      </div>
    </div>

    <div class="row mb-3 d-flex justify-content-center">

      <div
        class="col col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8"
      >
        <div
          class="lore d-flex flex-column align-items-center justify-content-between"
        >
          <div
            class="lore-description"
            [innerHTML]="selectedObject.lore | marked"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
