import { Injectable, OnDestroy, Optional } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { Subscription, Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { Database, ref, update, set, objectVal } from '@angular/fire/database';

@Injectable()
export class UserService implements OnDestroy {
  public user!: User | null;

  private readonly userSubscription!: Subscription;
  private profileSubscription!: Subscription;

  public readonly user$: Observable<User | null> = EMPTY;
  public readonly profile$: BehaviorSubject<any> = new BehaviorSubject(null);

  private _userProfile!: any;

  constructor(@Optional() private auth: Auth,
    public database: Database) {

    if (auth) {
      this.user$ = authState(this.auth);

      this.userSubscription = authState(this.auth)
        .pipe(traceUntilFirst('auth'))
        .subscribe((user) => {
          // Store the user object in a service to access from everywhere
          this.user = user;

          // If logged in check if it is the first time
          if (user) {
            // Set the reference to the profile observable.
            const profileRef = ref(database, `players/${this.auth.currentUser?.uid}/profile`);

            // Since there is no guarantee this is available on load,
            // We do trigger a custom Subject instead
            this.profileSubscription = objectVal(profileRef).subscribe((profile) => {
              // Store the profile locally
              this._userProfile = profile;

              // Notify all the listeners of the change
              this.profile$.next(this._userProfile);
            });

            const playerRef = ref(database, `players/${user?.uid}`);

            if (user?.metadata.creationTime === user?.metadata.lastSignInTime) {
              // Create or update the member record
              set(playerRef, {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                metadata: user.metadata,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                emailVerified: user.emailVerified,
                providerData: user.providerData,
                'profile/intro/complete': false,
              });
            } else if (user?.metadata.creationTime !== user?.metadata.lastSignInTime) {
              // Update the player record, need to update bc SET() just deletes all child objects if not passed
              update(playerRef, {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                metadata: user.metadata,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                emailVerified: user.emailVerified,
                providerData: user.providerData,
              });
            }
          }

        });
    }

  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe?.();
    this.profileSubscription?.unsubscribe?.();
  }

  public get profile() {
    return this._userProfile;
  }

  public get introComplete() {
    // This might have side effects, if userprofile or .intro are null, it returns false??
    return this._userProfile ? this._userProfile.intro ? this._userProfile.intro.complete : false : false;
  }

  public saveIntroComplete() {
    if (this.user) {
      // Set the reference to the profile observable.
      const profileRef = ref(this.database, `players/${this.auth.currentUser?.uid}/profile/intro`);

      update(profileRef, {
        complete: true,
        completed: new Date().getTime().toString()
      });
    } else {
      // Save to local storage too?
    }
  }
}
