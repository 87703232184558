<div class="stat d-flex align-items-start"
    >
  <i *ngIf="!large && !noIcon" class="fa-duotone" [ngClass]="icon"></i>
  <div class="d-flex flex-column" tooltip="{{ tooltipText }}"
  placement="top"
  container="body"
  [delay]="250">
    <h6>{{ name }}</h6>
    <h4 *ngIf="!large && value">{{ value }}</h4>
    <h1 *ngIf="large && value">{{ value }}</h1>
  </div>
</div>
