<div class="modal-header d-flex align-items-center">
  <i class="fa-duotone fa-dot-circle"></i>
  <h3 class="ms-3 mb-0 me-auto label">YOUR PROFILE</h3>
  <i
    class="close fa-duotone fa-times pull-right"
    (click)="dialog.hide()"
    tooltip="Close"
    placement="left"
    [delay]="500"
  ></i>
</div>

<div class="modal-body">
  <div class="container-fluid content">
    <div class="row">
      <velorum-profile></velorum-profile>
    </div>
  </div>
</div>
