import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable()
export class GoogleanalyticsService {
  constructor() {
    if (environment.production) {
      this.injectScripts();
    }
  }

  private injectScripts() {
    const head = document.querySelector('head');

    const gtagScript = document.createElement('script');
    gtagScript.src = environment.analytics.gtag;
    gtagScript.async = true;

    const gtagInitScript = document.createElement('script');
    gtagInitScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    `;

    head?.appendChild(gtagScript);
    head?.appendChild(gtagInitScript);
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue: number
  ) {
    // Google analytics only in prod
    if (environment.production) {
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue,
      });
    }
  }
}
