import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AvatarModalComponent } from '../../../components/avatar-modal/avatar-modal.component';
import { DetailsComponent } from '../../../components/details/details.component';
import { ProfileModalComponent } from '../../../components/profile-modal/profile-modal.component';
import { RegisterModalComponent } from '../../../components/register-modal/register-modal.component';
import { WalletModalComponent } from '../../../components/wallet-modal/wallet-modal.component';
import { GoogleanalyticsService } from './googleanalytics.service';

@Injectable()
export class WindowsService {
  public showOverlay = false;

  public detailsWindowReference!: BsModalRef;
  public registerWindowReference!: BsModalRef;
  public profileWindowReference!: BsModalRef;
  public walletWindowReference!: BsModalRef;
  public avatarWindowReference!: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private googleAnalytics: GoogleanalyticsService
  ) {}

  openDetails(selectedObject: any) {
    const initialState: ModalOptions = {
      animated: true,
      class:
        'modern-modal modal-xl modal-dialog-centered modal-dialog-scrollable',
      backdrop: true,
      initialState: {
        selectedObject,
      },
    };

    // Record a gtag event
    this.googleAnalytics.eventEmitter(
      'open_details_modal',
      'modals',
      'open',
      selectedObject.name,
      10
    );

    this.detailsWindowReference = this.modalService.show(
      DetailsComponent,
      initialState
    );
  }

  openRegister() {
    const initialState: ModalOptions = {
      animated: true,
      class:
        'modern-modal modal-lg modal-dialog-centered modal-dialog-scrollable',
      backdrop: true,
      initialState: {},
    };

    // Record a gtag event
    this.googleAnalytics.eventEmitter(
      'open_register_modal',
      'modals',
      'open',
      'register',
      10
    );

    this.registerWindowReference = this.modalService.show(
      RegisterModalComponent,
      initialState
    );
  }

  openProfileModal() {
    const initialState: ModalOptions = {
      animated: true,
      class:
        'ui-modal modal-lg modal-dialog-scrollable',
      backdrop: true,
      initialState: {},
    };

    // Record a gtag event
    this.googleAnalytics.eventEmitter(
      'open_profile_modal',
      'modals',
      'open',
      'profile',
      10
    );

    this.profileWindowReference = this.modalService.show(
      ProfileModalComponent,
      initialState
    );
  }

  openWalletModal() {
    const initialState: ModalOptions = {
      animated: true,
      class:
        'ui-modal modal-dialog-centered',
      backdrop: true,
      initialState: {},
    };

    // Record a gtag event
    this.googleAnalytics.eventEmitter(
      'open_wallet_modal',
      'modals',
      'open',
      'wallet',
      10
    );

    this.walletWindowReference = this.modalService.show(
      WalletModalComponent,
      initialState
    );
  }

  openAvatarModal() {
    const initialState: ModalOptions = {
      animated: true,
      class:
        'modern-modal modal-lg modal-dialog-centered modal-dialog-scrollable',
      backdrop: true,
      initialState: {},
    };

    // Record a gtag event
    this.googleAnalytics.eventEmitter(
      'open_pick_avatar_modal',
      'modals',
      'open',
      'avatar',
      10
    );

    this.avatarWindowReference = this.modalService.show(
      AvatarModalComponent,
      initialState
    );
  }

}
