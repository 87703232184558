<div class="row" >
  <div class="col col-12">
    <h5 class="p-2 text-center">
      Please sign in using any of these Providers:
    </h5>
  </div>
</div>
<div class="row mb-4">
  <div class="col col-3"></div>
  <div class="col col-6">
    <div class="d-flex flex-column">
      <button
        class="social-button btn btn-danger btn-google"
        type="button"
        (click)="google()"
      >
        <i class="fab fa-google"></i>Sign in with Google
      </button>
      <button
        class="social-button btn btn-primary btn-twitter"
        type="button"
        (click)="twitter()"
      >
        <i class="fab fa-twitter"></i>Sign in with Twitter
      </button>
    </div>
    <div class="col col-3"></div>
  </div>
</div>
