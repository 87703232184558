export const environmentCommon = {
  market: {
    enabled: false,
  },
  newmap: {
    visible: false,
  },
  grn: {
    visible: false,
  },
  meco: {
    visible: false,
  },
  profile: {
    ranks: false,
    avatar: false,
  },
  games: {
    grn: {
      path: 'https://tranquility.cloud.cdn.velorum.games/game/grn',
      debug: false,
      build: 'v1.2.0000'
    },
    meco: {
      path: 'https://tranquility.cloud.cdn.velorum.games/game/meco'
    }
  },
};
