import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'velorum-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent {

  constructor(public dialog: BsModalRef) { }


  onLoggedIn() {
    this.dialog.hide();
  }
}
