import { Component, Input } from '@angular/core';

@Component({
  selector: 'velorum-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
})
export class StatComponent {
  @Input() name?: string;
  @Input() value?: string;
  @Input() icon = 'fa-angle-double-right';
  @Input() noIcon = false;
  @Input() large!: boolean;
  @Input() tooltipText = '';
}
