import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UnityService } from '../services/unity.service';
import { MecoGameView } from '../modules/meco/views/game/game.view';

@Injectable()
export class DeactivateMecoGuard implements CanDeactivate<MecoGameView> {

  constructor(private unityService: UnityService) {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.unityService.destroyMeco();
  }
}
