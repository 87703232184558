import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { StatComponent } from './components/stat/stat.component';
import { RegisterComponent } from './components/register/register.component';
import { BootstrapModule } from '../bootstrap/bootstrap.module';
// Services
import { PhantomWalletService } from './services/phantom.service';
import { SolflareWalletService } from './services/solflare.service';
import { CoinbaseWalletService } from './services/coinbase.service';
import { UserService } from './services/user.service';
import { WindowsService } from './services/windows.service';
import { GoogleanalyticsService } from './services/googleanalytics.service';
import { TimerService } from './services/timer.service';
import { MusicPlayerService } from './services/musicplayer.service';
// Pipes
import { MarkedPipe } from './pipes/marked.pipe';
import { ProfileComponent } from './components/profile/profile.view';

// Export the string extension manually
export * from './extensions/string.extensions';

@NgModule({
  declarations: [StatComponent, RegisterComponent, ProfileComponent, MarkedPipe],
  imports: [CommonModule, BootstrapModule],
  providers: [],
  exports: [BootstrapModule, StatComponent, RegisterComponent, ProfileComponent, MarkedPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [TimerService, WindowsService, MusicPlayerService, PhantomWalletService, SolflareWalletService, CoinbaseWalletService, UserService, GoogleanalyticsService]
    };
  }
}
