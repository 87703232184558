import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'velorum-avatar-modal',
  templateUrl: './avatar-modal.component.html',
  styleUrls: ['./avatar-modal.component.scss']
})
export class AvatarModalComponent  {

  public avatars: any[] = [];

  constructor(public dialog: BsModalRef) {
    Array.from(Array(16).keys()).map(a => (a + 1).toString().padStart(2, '0').concat('.jpg')).forEach((v, i) => {
      this.avatars.push({
        image: v,
        available: i <= 3,
        selected: i == 3
      })
    });
  }



}
