<div class="me container-fluid">
  <div class="row">
    <div class="col">
        <div class="content-container">
          <div class="content container-fluid">
            <velorum-register *ngIf="!loggedIn"></velorum-register>

            <div class="row" *ngIf="loggedIn">
              <div class="col col-3 d-flex flex-column">
                <img class="your-avatar mb-3" [src]="photoURL" />
              </div>
              <div class="col col-9">
                <div class="row">
                  <div class="col col-6">
                    <velorum-stat
                      name="NAME"
                      [value]="displayName"
                      [large]="true"
                    ></velorum-stat>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-4">
                    <velorum-stat
                      name="TOTAL ASSETS"
                      value="0 USDC"
                    ></velorum-stat>
                    <velorum-stat
                      name="JOINED"
                      [value]="accountAge"
                    ></velorum-stat>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="loggedIn">
              <div class="col col-12 d-flex justify-content-end">
                <button
                  class="btn btn-outline-light sign-out pull-right"
                  (click)="logout()"
                >
                  <i class="fa-duotone fa-sign-out-alt"></i>
                  <span>SIGN OUT</span>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
