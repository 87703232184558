import { Component } from '@angular/core';
import { PhantomWalletService } from '../../modules/shared/services/phantom.service';
import { SolflareWalletService } from '../../modules/shared/services/solflare.service';

@Component({
  selector: 'velorum-wallet-status-badge',
  templateUrl: './wallet-status-badge.component.html',
  styleUrls: ['./wallet-status-badge.component.scss'],
})
export class WalletStatusBadgeComponent {
  constructor(public phantom: PhantomWalletService, public solflare: SolflareWalletService) {}

  get connected() {
    return this.phantom.connected || this.solflare.connected;
  }

  get connecting() {
    return this.phantom.connecting || this.solflare.connecting;
  }
}
