import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'velorum-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  @Input() selectedObject!: any;

  constructor(
    public dialog: BsModalRef,
    private lightbox: Lightbox,
  ) {}


  get imagesCdnPath() {
    return `//${environment.cdn.host}/${environment.cdn.path}/${environment.cdn.vcom.lore}`;
  }


  open() {
    this.lightbox.open([{
      src: `${this.imagesCdnPath}/${this.selectedObject.src}`,
      caption: this.selectedObject.caption,
      thumb: `${this.imagesCdnPath}/${this.selectedObject.thumb}`
    }], 0, {
      fadeDuration: 0.25,
      resizeDuration: 0.1,
      centerVertically: true
    });
  }
}
