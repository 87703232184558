<div class="modal-header">
  <div class="heading d-flex align-items-center">
    <i class="fa-duotone fa-dot-circle"></i>
    <h3>PICK YOUR AVATAR</h3>
  </div>
  <i
    class="close fa-duotone fa-times pull-right"
    (click)="dialog.hide()"
    tooltip="Close"
    placement="left"
    [delay]="500"
  ></i>
</div>

<div class="modal-body">
  <div class="container-fluid content">
    <div class="separator gold mb-4"></div>
      <h4 class="text-center">
        Eddy Lee Art Collection
      </h4>
    <div class="separator gold mt-4 mb-4"></div>
    <div class="d-flex flex-row flex-wrap justify-content-start align-items-center">
      <div class="avatar mb-2 me-2" [ngClass]="{'available': avatar.available, 'unavailable': !avatar.available, 'selected': avatar.selected}" *ngFor="let avatar of avatars">
        <div class="overlay" *ngIf="!avatar.available">
          <i class="locked fa-duotone fa-lock-alt"></i>
        </div>
        <i class="checked fa-duotone fa-badge-check" *ngIf="avatar.selected"></i>
        <img src="assets/avatars/eddylee/{{ avatar.image }}">
      </div>
    </div>
  </div>
</div>
