import { Component, EventEmitter, OnDestroy, Optional, Output } from '@angular/core';
import {
  Auth,
  authState,
  GoogleAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from '@angular/fire/auth';
import { Subscription } from 'rxjs';

import { traceUntilFirst } from '@angular/fire/performance';

@Component({
  selector: 'velorum-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy {
  @Output() loggedIn = new EventEmitter();

  private readonly userDisposable: Subscription | undefined;

  constructor(@Optional() private auth: Auth) {
    if (auth) {
      this.userDisposable = authState(this.auth)
        .pipe(traceUntilFirst('auth'))
        .subscribe((user) => {
          if (user) {
            this.loggedIn.emit();
          }
        });
    }
  }

  ngOnDestroy(): void {
  this.userDisposable?.unsubscribe?.();
  }

  async google() {
    return await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  async twitter() {
    return await signInWithPopup(this.auth, new TwitterAuthProvider());
  }

}
